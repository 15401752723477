import axios from "axios"
import { createAgendorWebhook, deleteAgendorWebhook, listAgendorWebhooks } from "./webhook"
import { getResource } from "../../utils"
import CRM from ".."

const baseUrl = "https://proxy.sharkdev.com.br/?url=https://api.agendor.com.br/v3"

export const validAgendorToken = async (token) => {
    try {
        await getAgendorFunnels(token)
        return true
    } catch (error) {
        throw error
    }
}

export const getAgendorFunnels = async (token) => {
    try {
        if (!token)
            throw { message: "Token não informado" }

        let result = await axios.get(`${baseUrl}/funnels`, {
            headers: {
                Authorization: `Token ${token}`
            }
        })

        // if (!result?.data?.success)
        //     throw { message: "Ocorreu um erro ao buscar os funis" }

        return result.data?.data
    } catch (error) {
        throw error
    }
}

export const getAgendorDealStages = async (token, pipeline_id) => {
    try {
        if (!token)
            throw { message: "Token não informado" }

        let result = await axios.get(`${baseUrl}/deal_stages?funnel=${pipeline_id}`, {
            headers: {
                Authorization: `Token ${token}`
            }
        })

        // if (!result?.data?.success)
        //     throw { message: "Ocorreu um erro ao buscar as etapas" }

        return result.data?.data
    } catch (error) {
        throw error
    }
}

export const getAgendorStatus = async (token) => {
    try {
        if (!token)
            throw { message: "Token não informado" }

        let result = await axios.get(`${baseUrl}/deal_statuses`, {
            headers: {
                Authorization: `Token ${token}`
            }
        })

        // if (!result?.data?.success)
        //     throw { message: "Ocorreu um erro ao buscar as etapas" }

        let items = result.data?.data?.map(({ id, name }) => ({
            id,
            label: name
        }))

        return items
    } catch (error) {
        throw error
    }
}

export const getAgendorTriggers = async () => [
    // { label: "Quando uma atividade/tarefa/comentário for criada", value: "on_activity_created" },
    // { label: "Quando uma empresa for criada", value: "on_organization_created" },
    // { label: "Quando uma empresa for alterada", value: "on_organization_updated" },
    { label: "Quando um negócio for criado", value: "on_deal_created" },
    { label: "Quando um negócio for perdido", value: "on_deal_lost" },
    { label: "Quando um negócio mudar de etapa", value: "on_deal_stage_updated" },
    { label: "Quando um negócio for alterado", value: "on_deal_updated" },
    { label: "Quando um negócio for ganho", value: "on_deal_won" },
    // { label: "Quando uma pessoa for criada", value: "on_person_created" },
    // { label: "Quando uma pessoa for alterada", value: "on_person_updated" },

    { label: "Na hora de uma tarefa de Nota", value: "on_task_time_nota" },
    { label: "Na hora de uma tarefa de E-mail", value: "on_task_time_email" },
    { label: "Na hora de uma tarefa de Ligação", value: "on_task_time_call" },
    { label: "Na hora de uma tarefa de Proposta", value: "on_task_time_proposal" },
    { label: "Na hora de uma tarefa de Reunião", value: "on_task_time_meeting" },
    { label: "Na hora de uma tarefa de Visita", value: "on_task_time_visit" },

    // Nota, E-mail, Ligação, Proposta, Reunião, Visita
]

export const getAgendorWebhook = async (token, {
    instance,
    pipeline,
    stage,
    triggers,
    status,
    active,
    webhook
}) => {
    try {
        let [
            webhookList,
            { items: activeMessagesList }
        ] = await Promise.all([
            listAgendorWebhooks(token),
            getResource({ key: CRM.activeMessagesResourceKey })
        ])

        console.log({ webhookList, activeMessagesList })

        if (Array.isArray(webhook)) {
            for (let id of webhook) {
                if (activeMessagesList.filter(({ webhook = [] }) => webhook.includes(id)).length <= 1) {
                    await deleteAgendorWebhook(token, id)
                    webhookList = webhookList.filter((item) => item.id != id)
                }
            }
        }

        let result = []

        for (let trigger of triggers) {
            let webhookAlreadyCreated = webhookList.find(({ target_url, event }) => target_url.indexOf("sharkdev") >= 0 && event == trigger)

            if (webhookAlreadyCreated)
                result.push(webhookAlreadyCreated.id)
            else {
                if (trigger.indexOf("on_task") < 0) {
                    let { id } = await createAgendorWebhook(token, {
                        target_url: `https://middlewareforblip.blip.sharkdev.com.br/${instance?.pipeline?._id}?trigger=${trigger}`,
                        event: trigger
                    })
                    result.push(id)
                }
            }
        }

        return result
    } catch (error) {
        console.error(error)
        throw error
    }
}

const Agendor = {
    pluginName: "Agendor",
    crmName: "Agendor",
    resourceKey: "agendorPlugin",
    activeMessagesResourceKey: "agendorPlugin_ActiveMessages",
    docUrl: "https://docs.sharkdev.com.br/blip/agendor",
    showWebhook: false,
    defaultStatus: () => [],
    variablesTips: () => [
        { text: "Nome da pessoa", value: "{{person.name}}" },
        { text: "Nome da organização", value: "{{organization.name}}" },
        { text: "Nome do dono do contato", value: "{{owner.name}}" },
        { text: "Nome do funil", value: "{{dealStage.funnel.name}}" },
        { text: "Nome da etapa", value: "{{dealStage.name}}" }
    ],
    middlewareForBlipService: "agendor",

    validToken: validAgendorToken,
    getPipelines: getAgendorFunnels,
    getStages: getAgendorDealStages,
    getTriggers: getAgendorTriggers,
    getStatus: getAgendorStatus,
    getWebhook: getAgendorWebhook
}

export default Agendor;